import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import * as R from "ramda";
import { InstallmentsButton } from "../InstallmentsButton";
import { BoxDate } from "../BoxContent";
import { BasicText } from "../Fonts/BasicText";
import { BigText } from "../Fonts/BigText";
import styled from "styled-components/macro";
import { selectEntities } from "../../selectors/debtStatus";
import { selectToken } from "../../selectors/token";
import { fetchDocument } from "../../util/api";
import { Currency } from "../Currency";
import { STATEMENT_STATUSES } from "../../constants/statementStatus";

import { messages } from "./messages";

export const Container = styled.div`
    margin: 12px 0px 12px 0px;
`;

const getNextStatement = (statements) => {
    const nextStatements = R.pipe(
        R.reject(R.propEq("statementStatus", STATEMENT_STATUSES.PAIDINFULL)),
        R.reject(R.propEq("statementStatus", STATEMENT_STATUSES.COLLECTION)),
        R.reject(R.propEq("statementStatus", STATEMENT_STATUSES.CURING))
    )(statements);

    const nextStatement = R.last(nextStatements);

    if (nextStatement) {
        return nextStatement;
    }

    const unpaidStatement = R.find(R.propEq("statementStatus", STATEMENT_STATUSES.UNPAID))(
        statements
    );

    return unpaidStatement;
};

export function NextInstallmentButton() {
    const getToken = useMemo(() => selectToken, []);
    const token = useSelector((state) => getToken(state));

    const getEntities = useMemo(selectEntities);
    const entities = useSelector((state) => getEntities(state));

    const statements = entities
        .filter((item) => item.statement)
        .map((item) => ({
            seriesNum: item.seriesNum,
            title: item.title,
            statementStatus: item.statement.status,
            dueDate: item.statement.dueDate,
            statementAmount: item.statement.statementAmount,
            unpaidAmount: item.statement.unpaidAmount
        }))
        .sort((a, b) => (a.seriesNum < b.seriesNum ? 1 : -1));

    if (!entities && entities.length === 0) {
        return null;
    }

    const statementToShow = getNextStatement(statements);

    if (statementToShow) {
        let messageId = messages.upcoming;

        if (statementToShow.statementStatus === STATEMENT_STATUSES.UNPAID) {
            messageId = messages.next;
        }

        return (
            <InstallmentsButton
                disabled
                onClick={() => fetchDocument({ documentId: statementToShow.documentId, token })}>
                <BasicText>
                    <FormattedMessage {...messageId} />
                </BasicText>
                <Container>
                    <BigText bold>
                        <Currency>{statementToShow.unpaidAmount}</Currency>
                    </BigText>
                </Container>
                <BoxDate>{statementToShow.dueDate}</BoxDate>
            </InstallmentsButton>
        );
    }

    return (
        <InstallmentsButton disabled={true}>
            <BasicText>
                <FormattedMessage {...messages.noStatement} />
            </BasicText>
        </InstallmentsButton>
    );
}
